import React from 'react';
import backgroundImage from './assets/background.jpg';

const handleClick = () => {
    navigator.clipboard.writeText("9AaVLhcXSy4aDYpgahj6aFNMRa7KxTjVsVa9jF4fzkLJ");
};

const LandingPage = () => {
  return (
    <div className="bg-[#FEBD59] min-h-screen">
        <div
        className="min-h-screen flex flex-col items-center justify-center"
        style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'contain',
            backgroundPosition: 'bottom',
            backgroundRepeat: 'no-repeat',
        }}
        >
            <header className="w-full text-white p-4">
                <div className="flex justify-center gap-32">
                    <a href="https://twitter.com/SolanaMegaCycle" className="text-4xl hover:text-5xl">
                        <p className="mr-2">twitter</p>
                    </a>
                    <a href="https://t.co/LWyrc8trVB" className="text-4xl hover:text-5xl">
                        <p className="mr-2">telegram</p>
                    </a>
                    <a href="#" className="text-4xl hover:text-5xl">
                        <p className="mr-2">dexscreener</p>
                    </a>
                </div>
            </header>
            <main className="flex-1 flex flex-col items-center pt-8">
                <p className="text-4xl hover:text-5xl text-white mb-4 cursor-pointer" onClick={handleClick}>CA: 9AaVLhcXSy4aDYpgahj6aFNMRa7KxTjVsVa9jF4fzkLJ</p>
                <a href="https://pump.fun/9AaVLhcXSy4aDYpgahj6aFNMRa7KxTjVsVa9jF4fzkLJ">
                    <p className="text-4xl hover:text-5xl text-white font-semibold">Buy $MEGA</p>
                </a>
            </main>
            <footer className="w-full text-white p-4 font-semibold text-center">
                &copy; 2024 $MEGA. All rights reserved.
            </footer>
        </div>
    </div>
  );
};

export default LandingPage;